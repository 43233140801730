/* buttons.css */
@import url('../common/variables.css');
@import url('../common/typography.css');

:root {
  --button-padding: 10px 20px;
  --button-border-radius: 7px;
  --button-font-size: 1em;
  --button-transition: 0.3s ease;
}

.button-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.button-container .button-wrapper {
  flex: 1;
}

.button  {
  padding: var(--button-padding);
  border: none;
  border-radius: var(--button-border-radius);
  font-size: var(--button-font-size);
  cursor: pointer;
  transition: background-color var(--button-transition), transform var(--button-transition);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  transform: scale(1.01);
}

.button:active {
  transform: scale(0.98);
}

.button .icon {
  margin-right: 8px;
}

.button.loading {
  cursor: wait;
}

.button.loading .icon {
  animation: spin 2s linear infinite;
}



.social-media-baseform .copy-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: opacity 0.3s ease;
  width: auto;
}

.form-buttons {
  flex: 1;
  width: 100%;
  min-width: 0;
  max-width: calc(50% - 10px);
}

.back-button .form-button {
  flex: 1;
  min-width: 0; 
}

.form-button, .generate-post-button, .submit-button {
  background-color: var(--weiß);
  color: #333;
}

.form-button{
  width: 100%;
}

.back-button {
  flex: 0 0 auto;
  min-width: fit-content;
  background-color: var(--weiß);
  color: #333;
}

.download-button {
  background-color: var(--tanne);
  color: var(--weiß);
  text-decoration: none;
}

.download-button,
.copy-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--tanne);
  color: white;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.file-input-wrapper,
.unsplash-search-button {
  background-color: var(--tanne);
  color: var(--weiß);
  border: 2px solid var(--weiß);
}

.file-input-wrapper .unsplash-search-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: var(--button-border-radius);
  padding: 10px 15px; /* Vernünftiges Padding hinzugefügt */
  background-color: var(--tanne);
  color: var(--weiß);
  border: 2px solid var(--weiß);
}

.file-input-wrapper:hover {
  background-color: var(--klee);
  transform: scale(1.01);
}

.generate-post-button:hover, 
.form-button:hover, 
.copy-button:hover {
  transform: scale(1.01);
}

/*submitbutton*/

.submit-button {
  position: relative;
  overflow: hidden;
  padding: var(--button-padding, 10px 20px);
  border: none;
  border-radius: var(--button-border-radius, 5px);
  font-size: var(--button-font-size, 1em);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.submit-button--loading {
  cursor: loading;
}

.submit-button--success {
  background-color: var(--success-color, #28a745);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.submit-button__lottie-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button__lottie-animation {
  width: 100%;
  height: 100%;
}

.submit-button__content {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
}

.submit-button__content--visible {
  opacity: 1;
}

.submit-button__content--hidden {
  opacity: 0;
}

.submit-button__icon {
  margin-right: 8px;
}

.submit-button__icon--loading {
  animation: spin 2s linear infinite;
}

.file-input-wrapper input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-input-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  padding: var(--button-padding);
  border: 2px solid var(--weiß);
  border-radius: var(--button-border-radius);
  font-size: var(--button-font-size);
  background-color: var(--tanne);
  color: var(--weiß);
  cursor: pointer;
  transition: background-color var(--button-transition), transform var(--button-transition);
}

.file-input-text:hover {
  background-color: var(--klee);
  transform: scale(1.01);
}

.file-input-text span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  padding: 0 8px;
}

.file-input-text svg {
  flex-shrink: 0;
  font-size: 1.2em;
}

.file-name {
  display: none;
}

.file-upload {
  border: 2px dashed var(--weiß);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
  background-color: transparent;
  color: var(--weiß);
  justify-content: center;
  transition: border-color 0.3s, background-color 0.3s;
}

.file-upload.dragging {
  border-color: var(--sonne);
}

.file-upload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  justify-content: center;
  cursor: pointer;
  background-color: var(--sand)
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .button,
  .form-button,
  .submit-button,
  .generate-post-button,
  .back-button,
  .download-button,
  .copy-button,
  .image-search-button,
  .file-input-wrapper {
    font-size: 0.85em;
    padding: 8px 16px; /* Reduziertes Padding für mobile Geräte */
  }

  .download-button {
    border-radius: 20px;
    padding: 12px 10px;
  }
}

/* Gemeinsame Styles für FileUpload, UnsplashButton und AlternativesButton */
.file-input-text,
.unsplash-search-button,
.show-alternatives-button {
  width: 100%;
  height: 42px;
  padding: var(--button-padding);
  border: 2px solid var(--weiß);
  border-radius: var(--button-border-radius);
  font-size: var(--button-font-size);
  cursor: pointer;
  transition: background-color var(--button-transition), transform var(--button-transition);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tanne);
  color: var(--weiß);
  gap: 8px;
  white-space: nowrap;
}

.file-input-text:hover,
.unsplash-search-button:hover,
.show-alternatives-button:hover {
  background-color: var(--klee);
  transform: scale(1.01);
}

.file-input-text svg,
.unsplash-search-button svg {
  font-size: 1.2em;
}

.file-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .file-input-text,
  .unsplash-search-button,
  .show-alternatives-button {
    height: 48px;
    font-size: 0.95em;
    padding: 8px 16px;
  }
}

/* Action Buttons Container (für die drei Buttons in einer Reihe) */
.action-buttons {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.action-buttons.three-buttons {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.action-buttons.three-buttons .button-wrapper {
  flex: 1;
}

.alternatives-button {
  width: 100%;
  font-size: 0.9em;
  padding: 0.5rem 1rem;
  background-color: var(--tanne);
  color: var(--weiß);
  border: 2px solid var(--weiß);
  border-radius: var(--button-border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
}

.alternatives-button:hover {
  background-color: var(--klee);
}

.alternatives-button-wrapper {
  position: relative;
  height: 42px;
  width: auto;
}

.button-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .action-buttons.three-buttons {
    flex-wrap: wrap;
    gap: 0.75rem;
  }
  
  .action-buttons.three-buttons .button-wrapper {
    flex: 1 1 100%;
  }

  .action-buttons.three-buttons .button-wrapper:nth-child(2),
  .action-buttons.three-buttons .button-wrapper:nth-child(3) {
    flex: 1 1 calc(50% - 0.375rem);
  }
}

/* Spezifische Styles für Slogan-Alternative Buttons */
.alternatives-button-wrapper {
  position: relative;
  flex: 1;
  min-width: 0;
  height: 42px;
}

.show-alternatives-button,
.hide-alternatives-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--button-padding);
  border: 2px solid var(--weiß);
  border-radius: var(--button-border-radius);
  font-size: var(--button-font-size);
  cursor: pointer;
  transition: all var(--button-transition);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tanne);
  color: var(--weiß);
  gap: 8px;
  white-space: nowrap;
}

.show-alternatives-button:hover,
.hide-alternatives-button:hover {
  background-color: var(--klee);
  transform: scale(1.01);
}

/* Button Fade Transition */
.button-fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.button-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.button-fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.button-fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

@media (max-width: 768px) {
  .alternatives-button-wrapper {
    height: 48px;
  }

  .show-alternatives-button,
  .hide-alternatives-button {
    font-size: 0.95em;
    padding: 8px 16px;
  }
}

/* Sharepic Download Button */
.sharepic-download-button {
  width: 100%;
  height: 42px;
  padding: var(--button-padding);
  border: none;
  border-radius: var(--button-border-radius);
  font-size: var(--button-font-size);
  line-height: 1;
  cursor: pointer;
  transition: background-color var(--button-transition), transform var(--button-transition);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tanne);
  color: var(--weiß);
  gap: 8px;
  white-space: nowrap;
  text-decoration: none;
}

.sharepic-download-button:hover {
  background-color: var(--klee);
  transform: scale(1.01);
}

.sharepic-download-button svg {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .sharepic-download-button {
    height: 48px;
    font-size: 0.95em;
    padding: 8px 16px;
    line-height: 1;
  }
  
  .sharepic-download-button svg {
    font-size: 1.2em;
  }
}
