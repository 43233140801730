/* Imports */
@import url('../common/variables.css');

/* Welcome Screen */
.welcome-screen {
  min-height: calc(100vh - 300px);
  width: 100%;
  background-color: var(--background-color);
  background-image: 
    radial-gradient(circle at 5% 10%, var(--klee-transparent) 0%, transparent 15%),
    radial-gradient(circle at 95% 90%, var(--tanne-transparent) 0%, transparent 12%),
    radial-gradient(circle at 90% 5%, var(--sand-transparent) 0%, transparent 8%);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px;
  margin-top: -40px;
  opacity: 1;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
}

.welcome-content {
  max-width: 1200px;
  width: 100%;
  text-align: left;
  padding: 0 40px 40px;
  margin: 0 auto;
  position: relative;
  background: linear-gradient(170deg, var(--background-color) 0%, var(--background-color-alt) 100%);
  border-radius: 20px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.08),
              0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--button-hover-color);
}

.welcome-content h1 {
  font-size: clamp(2em, 4vw, 2.8em);
  color: var(--font-color-h);
  margin-bottom: 30px;
  font-weight: bold;
  padding: 30px clamp(20px, 4vw, 40px) 0;
  position: relative;
}

@media (prefers-color-scheme: light) {
  .welcome-content h1 {
    background: linear-gradient(90deg, var(--klee) -50%, var(--font-color-h) 150%);
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
}

.welcome-intro {
  font-size: clamp(1em, 2vw, 1.2em);
  line-height: 1.6;
  color: var(--font-color);
  max-width: min(1000px, 90%);
  margin: 0 0 40px clamp(20px, 4vw, 40px);
  padding: 20px 25px;
  border-left: 3px solid var(--klee);
  background: var(--background-color-alt);
  border-radius: 0 16px 16px 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.02);
}

.welcome-steps {
  margin: clamp(20px, 4vw, 40px) 0;
  position: relative;
  padding: 20px;
  background: linear-gradient(165deg, var(--background-color-alt) 0%, transparent 100%);
  border-radius: 16px;
}

.welcome-steps h2 {
  font-size: clamp(1.4em, 3vw, 1.8em);
  color: var(--font-color-h);
  margin-bottom: clamp(30px, 3vw, 40px);
  padding-left: clamp(20px, 4vw, 40px);
  position: relative;
}

.welcome-steps h2::before {
  display: none;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
  gap: clamp(20px, 3vw, 30px);
  margin: 0 auto;
  max-width: min(1000px, 90%);
  padding: 0 clamp(10px, 2vw, 20px);
  position: relative;
}

.step-card {
  padding: clamp(25px, 3vw, 30px) clamp(20px, 2vw, 25px);
  border-radius: 16px;
  position: relative;
  transition: all 0.4s ease;
  border: 1px solid var(--button-hover-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06),
              0 2px 8px rgba(0, 0, 0, 0.04);
}

@media (prefers-color-scheme: light) {
  .step-card {
    background: linear-gradient(135deg, var(--background-color) 0%, var(--background-color-alt) 100%);
  }
  
  .step-card:hover {
    background: linear-gradient(135deg, var(--background-color-alt) 0%, var(--background-color) 100%);
  }
}

@media (prefers-color-scheme: dark) {
  .step-card {
    background: var(--background-color-alt);
  }
  
  .step-card:hover {
    background: var(--background-color);
  }
}

.step-card:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1),
              0 4px 12px rgba(0, 0, 0, 0.05);
  border-color: var(--klee);
}

.step-number {
  width: clamp(32px, 4vw, 36px);
  height: clamp(32px, 4vw, 36px);
  background: linear-gradient(135deg, var(--klee) 0%, var(--tanne) 150%);
  color: var(--weiß);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(1.1em, 2vw, 1.3em);
  font-weight: bold;
  position: absolute;
  top: -18px;
  left: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-card h3 {
  color: var(--font-color-h);
  font-size: clamp(1.1em, 2vw, 1.2em);
  margin: 15px 0 12px;
}

.step-card p {
  color: var(--font-color);
  line-height: 1.5;
  font-size: clamp(0.9em, 1.5vw, 0.95em);
  opacity: 0.9;
  flex-grow: 1;
}

.start-button {
  background: linear-gradient(135deg, var(--klee) 0%, var(--tanne) 150%);
  color: var(--weiß);
  border: none;
  padding: clamp(14px, 2vw, 16px) clamp(35px, 4vw, 40px);
  border-radius: 30px;
  font-size: clamp(1.1em, 2vw, 1.3em);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.4s ease;
  margin: clamp(30px, 4vw, 40px) auto 0;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  display: block;
  width: fit-content;
  min-width: min(250px, 80%);
  position: relative;
  overflow: hidden;
}

.start-button:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  background: linear-gradient(135deg, var(--tanne) -50%, var(--klee) 150%);
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .welcome-content {
    padding: clamp(25px, 4vw, 30px);
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .welcome-screen {
    padding: 15px;
    min-height: calc(100vh - 120px);
    margin-top: 0;
  }

  .welcome-content {
    padding: 20px 15px;
  }

  .steps-grid {
    padding: 0 10px;
  }

  .step-card {
    margin-top: 15px;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .welcome-screen {
    padding: 10px;
  }

  .welcome-content {
    padding: 15px 10px;
  }

  .steps-grid {
    gap: 25px;
  }
}

/* Landscape Mode */
@media (max-height: 600px) and (orientation: landscape) {
  .welcome-screen {
    min-height: auto;
    padding: 60px 15px;
  }

  .steps-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Animation Styles */
.welcome-screen.fade-out {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-20px);
}

.fade-out .welcome-content {
  opacity: 0;
  transform: translateY(-20px);
}

/* BaseForm Transition */
.welcome-screen .base-container {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease-out 0.3s forwards;
  position: relative;
  z-index: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 