.verify-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
  transition: background-color 0.3s ease;
}

.verify-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.verify-box {
  background: var(--background-color);
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  width: 90%;
  animation: slideIn 0.3s ease-out;
  border: 1px solid var(--background-color-alt);
  transition: all 0.3s ease;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.verify-box h2 {
  color: var(--tanne);
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  text-align: center;
}

.verify-box p {
  color: #666;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 0.95rem;
}

.verify-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.verify-input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.verify-input {
  width: 100%;
  padding: 0.8rem 1rem;
  padding-right: 3rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.verify-input:focus {
  border-color: var(--tanne);
  outline: none;
  box-shadow: 0 0 0 3px rgba(var(--tanne-rgb), 0.1);
}

.verify-password-toggle {
  position: absolute;
  right: 0.8rem;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: #666;
  transition: color 0.2s ease;
}

.verify-password-toggle:hover {
  color: var(--tanne);
}

.verify-error {
  color: #d32f2f;
  font-size: 0.9rem;
  text-align: center;
  background-color: rgba(211, 47, 47, 0.1);
  padding: 0.75rem;
  border-radius: 6px;
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

.verify-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.verify-button {
  flex: 1;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: var(--tanne);
  color: var(--weiß);
}

.verify-button:hover {
  background-color: var(--klee);
  transform: translateY(-1px);
}

.verify-button:active {
  transform: translateY(0);
}

.verify-button-secondary {
  background-color: var(--background-color);
  color: var(--tanne);
  border: 2px solid var(--tanne);
}

.verify-button-secondary:hover {
  background-color: #e9ecef;
}

/* Sperrzustand Styling */
.verify-box.locked .verify-input {
  border-color: #d32f2f;
  background-color: rgba(211, 47, 47, 0.05);
}

.verify-box.locked .verify-button {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Fortschrittsanzeige für verbleibende Versuche */
.verify-attempts {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin: 0.5rem 0;
}

.attempt-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--background-color-alt);
  transition: all 0.3s ease;
}

.attempt-dot.active {
  background-color: var(--klee);
}

.attempt-dot.failed {
  background-color: #d32f2f;
}

/* Dark Mode Anpassungen für neue Elemente */
[data-theme="dark"] .attempt-dot {
  background-color: var(--background-color);
}

[data-theme="dark"] .attempt-dot.active {
  background-color: var(--klee);
}

[data-theme="dark"] .attempt-dot.failed {
  background-color: #ff4444;
}

[data-theme="dark"] .verify-box.locked .verify-input {
  background-color: rgba(211, 47, 47, 0.1);
}

/* Neue Sicherheitsfunktionen */
.verify-attempts {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin: 0.5rem 0;
}

.attempt-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--background-color-alt);
}

.attempt-dot.active {
  background-color: var(--klee);
}

.attempt-dot.failed {
  background-color: #d32f2f;
}

.verify-box.locked .verify-input,
.verify-box.locked .verify-button {
  opacity: 0.5;
  cursor: not-allowed;
} 