/* Platform Container Styles */
.platform-container {
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 12px;
  background: var(--background-color);
  border: 1px solid var(--background-color-alt);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

[data-theme='dark'] .platform-container {
  background: var(--background-color);
  border-color: var(--background-color-alt);
}

.platform-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin: 0;
  background: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out;
}

.platform-content:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}

.platform-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.platform-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.platform-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.03);
}

.platform-name {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.platform-divider {
  border: none;
  border-top: 1px solid;
  margin: 0 0 1rem 0;
  opacity: 0.5;
}

.platform-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0;
}

.generated-content-wrapper {
  flex: 1;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1rem;
  line-height: 1.6;
}

/* Grid Layout für mehrere Plattformen */
.platforms-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 100%;
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
  .platform-content {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
  
  .platform-container {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }
  
  .platform-header {
    padding-bottom: 8px;
  }

  .platform-divider {
    display: none;
  }

  .platform-body {
    padding: 0;
  }

  .platform-icon {
    width: 28px;
    height: 28px;
  }

  .platform-name {
    font-size: 1rem;
  }

  .platforms-container {
    gap: 2rem;
  }
} 