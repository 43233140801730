/* Gemeinsame Basis-Stile */
.flex-base {
    display: flex;
    flex-direction: column;
}
  
.container-base {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    transition: all 0.3s ease;
}
  
/* Überschriften Basis-Stile */
.form-container h3,
.display-container h3 {
    font-family: 'GrueneType';
    font-size: 1.5em;
    text-align: left;
    margin-top: 0;
}

.form-container h3 {
    color: var(--sand);
    margin-bottom: -12px;
}

.display-container h3 {
    color: var(--font-color-h3);
    margin-bottom: 8px;
}

.sidebar h2 {
    font-family: var(--font-family-heading);
    font-size: 2em;
    color: var(--weiß);
    margin-top: 0;
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
    .form-container h3,
    .display-container h3 {
        font-size: 1.15em;
    }

    .sidebar h2 {
        font-size: 1.5em;
    }
}

/* Subtext Basis-Stile */
.form-container .subtext {
    color: var(--sand);
    font-size: 0.9em;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: left;
}
  
/* Input und Form Basis-Stile */
.input-base,
.form-container input,
.form-container textarea,
.form-container select,
.sidebar input,
.sidebar textarea,
.sidebar select {
    width: 100%;
    padding: 8px;
    border: 2px solid transparent;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: var(--font-family-base);
    font-size: 0.9em;
    outline: none;
    color: var(--dunkelgrau);
    background-color: var(--weiß);
    transition: all 0.3s ease;
    margin: 15px 0;
}

.input-base:focus,
.form-container input:focus,
.form-container textarea:focus,
.form-container select:focus,
.sidebar input:focus,
.sidebar textarea:focus {
    border: 2px solid var(--grashalm);
    padding: 8px;
}

/* Textarea spezifische Höhe */
.form-container textarea,
.sidebar textarea {
    height: 120px;
}
  
.header-base {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}