/* Reset und Basis-Styles */
@import './common/reset.css';
@import './common/variables.css';
@import './common/typography.css';
@import './common/global.css';
@import './common/shared-components.css';
@import './components/action-buttons.css';

/* Komponenten */
@import './components/button.css';
@import './components/header.css';
@import './components/footer.css';
@import './components/navmenu.css';
@import './components/editor.css';
@import './components/sharepic.css';
@import './components/advanced-editing.css';
@import './components/image-slider.css';
@import './components/popup.css';
@import './components/tooltip.css';
@import './components/quill-custom.css';
@import './components/linksave.css';
@import './components/fileUpload.css';
@import './components/exportToDocument.css';
@import './components/BackupToggle.css';
@import './components/welcome.css';
@import './components/help.css';
@import './components/platform-container.css';
@import './components/animatedcheckbox.css';

/* Seiten-spezifische Styles */
@import './pages/baseform.css';
@import './pages/template-gallery.css';
@import './pages/antragsversteher.css';
@import './pages/Startseitenstyle.css';
@import './pages/Impressum_datenschutz.css';

/* Basis-Styles */
body {
  font-family: 'PT Sans', Arial, sans-serif !important;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* Globale Styles */
* {
  font-family: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

button,
input,
select,
textarea {
  font: inherit;
  color: inherit;
}