/* Home Page Styles */
.top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50vh;
    padding: 8rem 3.75rem 2.75rem;
    max-width: 1200px;
    margin: 0 auto;
    background-color: var(--background-color);
}

.animated-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    width: 100%;
}

.animated-heading .typing-text,
.animated-heading h2 {
    font-size: 3.7em;
    font-weight: 700;
    color: var(--font-color-h);
    font-family: 'GrueneType', Arial, sans-serif;
    margin: 0;
}

.animated-heading .typing-text {
    display: block;
}

.animated-heading h2 {
    line-height: 1;
}

.top-section p {
    font-size: 1.2em;
    line-height: 1.6;
    color: var(--font-color);
    max-width: 1200px;
    margin-bottom: 40px;
    text-align: left;
    align-self: flex-start;
}

/* Button Styles */
.link-buttons-container {
    width: 100%;
    max-width: 1200px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.link-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    width: 100%;
}

.link-buttons a,
.link-buttons button {
    color: var(--font-color);
    font-size: 1.1em;
    text-decoration: none;
    background-color: var(--background-color-alt);
    padding: 20px;
    border-radius: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

/* Icon Styles für alle Buttons */
.link-buttons a svg,
.link-buttons button svg {
    margin-right: 12px;
    font-size: 1.3em;
}

/* Spezielle Icon Styles für Newsletter und Mehr Button */
.newsletter-button svg {
    margin-right: 0;
    margin-left: 10px;
}

.more-button svg {
    margin-left: 8px;
    margin-right: 0;
    transition: transform 0.3s ease;
}

.more-button.active svg {
    transform: rotate(180deg);
}

.more-button svg {
    margin-left: 8px;
    margin-right: 0;
    transition: transform 0.3s ease;
}

.more-button.active svg {
    transform: rotate(180deg);
}

.secondary-buttons {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    grid-template-columns: repeat(5, 1fr) !important;
}

.secondary-buttons.show {
    display: grid;
    opacity: 1;
}

.newsletter-button {
    background-color: var(--tanne) !important;
    color: white !important;
}

.newsletter-button svg {
    margin-right: 0;
    margin-left: 10px;
}

.newsletter-button:hover {
    opacity: 0.9;
    background-color: var(--tanne) !important;
}

.newsletter-button:hover svg {
    transform: translateX(3px);
}

.mobile-only {
    display: none !important;
}

@media (max-width: 768px) {
    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: flex !important;
    }

    .link-buttons {
        grid-template-columns: 1fr;
        max-width: 400px;
    }

    .secondary-buttons {
        display: grid;
        opacity: 1;
    }

    .more-button {
        display: none !important;
    }

    .link-buttons a,
    .link-buttons button {
        justify-content: flex-start;
        padding-left: 25px;
    }

    .link-buttons a svg,
    .link-buttons button svg {
        margin-right: 12px;
        font-size: 1.3em;
    }

    .newsletter-button.mobile-only {
        display: flex !important;
        width: 100%;
        justify-content: flex-start;
        text-align: left;
        padding: 1rem 1.5rem;
        font-size: 1.1em;
        border-radius: 10px;
        margin-top: 0.75rem;
    }

    .newsletter-button svg {
        margin-left: auto;
        margin-right: 0;
    }
}

/* Sections Styles */
.sections-container {
    padding: 3.75rem 0;
    background-color: var(--background-color-alt);
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.75rem 3.75rem;
    margin: 0 auto;
    max-width: 1200px;
    gap: 3.75rem;
    background-color: var(--background-color-alt);
}

.text-column {
    flex: 0.45;
    padding: 0;
    box-sizing: border-box;
    min-width: 250px;
    color: var(--font-color);
}

.text-column h2 {
    margin: 0 0 1rem;
    font-size: 2.4em;
}

.text-column p {
    margin: 0;
    font-size: 1.2em;
    line-height: 1.6;
}

.image-column {
    flex: 0.40;
    padding: 20px;
    box-sizing: border-box;
    min-width: 300px;
}

.image-column img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.image-column img:hover {
    transform: scale(1.02);
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .top-section {
        padding: 7.5rem 1.25rem 2.5rem;
    }

    .section {
        padding: 2rem 1.25rem;
        gap: 2rem;
    }

    .link-buttons {
        gap: 0.5rem;
    }

    .link-buttons a {
        padding: 0.9rem;
        font-size: 1em;
    }
}

@media (min-width: 769px) {
    .section:nth-child(even) {
        flex-direction: row-reverse;
    }
}

@media (max-width: 768px) {


    .animated-heading {
        align-items: flex-start;
    }

    .animated-heading .typing-text,
    .animated-heading h2 {
        font-size: 2.5em;
        text-align: left;
        line-height: 1.2;
    }

    .top-section p {
        font-size: 1.1em;
        text-align: left;
        padding: 0 0.5rem;
        margin-bottom: 2rem;
    }

    .link-buttons-container {
        align-items: center;
    }

    .link-buttons {
        grid-template-columns: 1fr;
        width: 100%;
        max-width: 100%;
        gap: 0.75rem;
    }

    .link-buttons a,
    .link-buttons button {
        padding: 1rem 1.5rem;
        font-size: 1.1em;
        border-radius: 10px;
        width: 100%;
        justify-content: flex-start;
    }

    .sections-container {
        padding: 1.5rem 0;
    }

    .section {
        flex-direction: column;
        padding: 2rem 1rem;
        gap: 2rem;
        text-align: center;
    }

    .text-column {
        flex: 1;
        width: 100%;
        padding: 0 0.5rem;
    }

    .text-column h2 {
        font-size: 2em;
        margin-bottom: 1rem;
    }

    .text-column p {
        font-size: 1.1em;
        line-height: 1.5;
        text-align: left;
    }

    .image-column {
        flex: 1;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .image-column img {
        max-width: 85%;
        height: auto;
        margin: 0;
    }

    .newsletter-button.mobile-only {
        padding: 0.875rem 1.25rem;
        font-size: 1em;
    }
}

@media (max-width: 576px) {
    .section {
        padding: 1rem;
    }

    .image-column img {
        max-width: 90%;
        margin: 1.5rem auto 0;
    }

    .link-buttons a {
        font-size: 0.8em;
        border-radius: 8px;
        padding: 0.75rem;
    }
}

@media (max-width: 480px) {
    .animated-heading .typing-text,
    .animated-heading h2 {
        font-size: 2em;
    }

    .top-section p {
        font-size: 1em;
    }

    .link-buttons a,
    .link-buttons button {
        padding: 0.875rem 1.25rem;
        font-size: 1em;
    }

    .text-column h2 {
        font-size: 1.75em;
    }

    .text-column p {
        font-size: 1em;
    }

    .image-column img {
        max-width: 95%;
    }

    .newsletter-button.mobile-only {
        padding: 0.875rem 1.25rem;
        font-size: 1em;
    }
}

.link-buttons a:hover,
.link-buttons button:hover {
    background-color: var(--button-hover-color);
    transform: translateY(-2px);
}

@media (max-width: 1024px) {
    .secondary-buttons {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

@media (max-width: 768px) {
    .link-buttons {
        grid-template-columns: 1fr;
        max-width: 400px;
    }

    .secondary-buttons {
        grid-template-columns: 1fr !important;
    }
}