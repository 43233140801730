/* Basis-Styling für react-tooltip */
#action-tooltip {
    background-color: var(--dunkelgruen) !important;
    color: white !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    padding: 8px 12px !important;
    z-index: 9999 !important;
    opacity: 1 !important;
  }
  
  /* Pfeil-Styling - jetzt für bottom position */
  #action-tooltip.react-tooltip::before {
    border-bottom-color: var(--grau) !important;
    border-top-color: transparent !important;
  }
