@import url('../common/variables.css');

/* Grundlegende Container-Struktur */
.sharepic-base-container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px;
    position: relative;
    align-items: stretch;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

/* Formular-Container */
.sharepic-base-container .form-container {
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--dunkelgruen);
    align-self: flex-start;
    transition: all 0.3s ease-in-out;
}

/* Display-Container */
.sharepic-base-container .display-container {
    flex: 1 1 50%;
    max-width: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 8px;
    background-color: var(--background-color-alt);
    transition: padding 0.3s ease-in-out;
}

/* Result Step Proportionen */
.sharepic-base-container.result-step .form-container {
    flex: 0 0 65%;
    max-width: 65%;
    width: 65%;
}

.sharepic-base-container.result-step .display-container {
    flex: 0 0 35%;
    max-width: 35%;
    width: 35%;
}

/* Image Modification Controls */
.image-modification-controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 1.5rem;
}

.image-modification-controls .left-column,
.image-modification-controls .right-column {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* Gruppierungen */
.textzeilen-group,
.absender-group,
.color-controls,
.font-size-group,
.social-media-group {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Einheitliche Überschriften */
.textzeilen-group h3,
.absender-group h3,
.color-controls h3,
.font-size-group h3,
.social-media-group h3 {
    color: var(--weiß);
    margin: 0;
    font-size: 1.1em;
    font-weight: 600;
}

/* Einheitliche Beschreibungstexte */
.textzeilen-group p,
.absender-group p,
.color-controls p,
.font-size-group p,
.social-media-group p {
    color: var(--weiß);
    margin: 0 0 0.5rem 0;
    font-size: 0.9em;
    opacity: 0.9;
    line-height: 1.4;
}

/* Einheitliche Abstände für Controls */
.color-scheme-control,
.font-size-control,
.credit-control {
    margin-top: 0.5rem;
}

/* Color Scheme Control */
.color-scheme-control {
    width: 100%;
}

.color-scheme-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: start;
}

.color-scheme-option {
    cursor: pointer;
    transition: transform 0.3s ease;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-scheme-image {
    width: auto;
    height: auto;
    display: block;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: all 0.3s ease;
}

.color-scheme-option.active .color-scheme-image {
    border-color: var(--sand);
    transform: scale(0.95);
}

.color-scheme-option:hover .color-scheme-image {
    border-color: var(--klee);
    transform: scale(0.98);
}

/* Font Size Control */
.font-size-control {
    margin: 0;
}

.font-size-buttons {
    display: flex;
    gap: 0.75rem;
}

.font-size-buttons button {
    flex: 1;
    padding: 0.75rem;
    border-radius: 4px;
    border: 2px solid var(--weiß);
    color: var(--weiß);
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
}

.font-size-buttons button:hover {
    background-color: var(--grashalm);
}

.font-size-buttons button.active {
    background-color: var(--klee);
}

/* Credit Control */
.credit-control input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 4px;
    border: 2px solid var(--weiß);
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--weiß);
    font-size: 1em;
    transition: all 0.3s ease;
}

.credit-control input:focus {
    outline: none;
    border-color: var(--klee);
    background-color: rgba(0, 0, 0, 0.3);
}

.credit-control input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.social-media-button {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--klee);
    color: var(--weiß);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0;
}

.social-media-button:hover {
    background-color: var(--grashalm);
}

/* Form Controls */
.sharepic-form-container h3,
.sharepic-form-container h4 {
    color: var(--weiß);
    margin-bottom: 0;
}

/* Advanced Editing */
.sharepic-advanced-editing-button-container {
    margin: 2rem 0;
    width: 100%;
}

.sharepic-advanced-editing-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background-color: transparent;
    color: var(--weiß);
    border: 2px solid var(--weiß);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Media Queries */
@media (max-width: 1024px) {
    .sharepic-image-modification-controls {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .sharepic-base-container {
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        margin: 0;
    }

    .sharepic-base-container .form-container,
    .sharepic-base-container .display-container,
    .sharepic-base-container.result-step .form-container,
    .sharepic-base-container.result-step .display-container {
        flex: 1 1 100%;
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 16px;
    }

    /* Spezielle Anpassungen für den Result-Step */
    .sharepic-base-container.result-step {
        display: flex;
        flex-direction: column;
    }

    .sharepic-base-container.result-step .display-container {
        order: -1; /* Bringt den Display-Container nach oben */
    }

    .sharepic-base-container.result-step .form-container {
        width: 100%;
        max-width: 100%;
    }

    .image-modification-controls {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .textzeilen-group,
    .absender-group,
    .color-controls,
    .font-size-group,
    .social-media-group {
        padding: 1rem;
    }

    .textzeilen-group h3,
    .absender-group h3,
    .color-controls h3,
    .font-size-group h3,
    .social-media-group h3 {
        font-size: 1em;
        margin-bottom: 0.5rem;
    }

    .textzeilen-group p,
    .absender-group p,
    .color-controls p,
    .font-size-group p,
    .social-media-group p {
        font-size: 0.9em;
        margin-bottom: 0.5rem;
    }

    .button-container {
        flex-direction: column;
        gap: 0.75rem;
    }

    .button-container .button-wrapper {
        width: 100%;
    }

    .font-size-buttons {
        flex-wrap: wrap;
    }

    .font-size-buttons button {
        flex: 1 1 calc(50% - 0.375rem);
        padding: 0.5rem;
        font-size: 0.9em;
    }

    .color-scheme-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.75rem;
    }

    .color-scheme-image {
        width: 100%;
        height: auto;
    }

    .credit-control input {
        padding: 0.5rem;
        font-size: 0.9em;
    }

    .social-media-button {
        padding: 0.5rem;
        font-size: 0.9em;
    }

    /* Sticky Preview im mobilen Layout */
    .sticky-sharepic-container {
        position: sticky;
        top: 0;
        z-index: 10;
        background: var(--background-color-alt);
        padding: 1rem;
        margin: -1rem;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .sticky-sharepic {
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    /* Verbesserte Input-Felder für Touch */
    input[type="text"],
    textarea {
        font-size: 16px; /* Verhindert Zoom auf iOS */
        padding: 0.75rem;
    }

    /* Bessere Touch-Targets */
    button,
    .button-wrapper {
        min-height: 44px;
    }
}

/* Animations */
@media (prefers-reduced-motion: reduce) {
    .sharepic-base-container * {
        transition: none !important;
    }
}

/* Button Container */
.button-container {
    display: flex;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
}

.button-container .button-wrapper {
    flex: 1;
}

.button-container .form-button,
.button-container .back-button,
.button-container .sharepic-file-input-text,
.button-container .sharepic-unsplash-search-button {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Unsplash Button */
.sharepic-unsplash-search-button {
    padding: 0.5rem 1rem;
    background-color: var(--tanne);
    color: var(--weiß);
    border: 2px solid var(--weiß);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.sharepic-unsplash-search-button svg {
    margin-right: 8px;
}

.sharepic-unsplash-search-button:hover {
    background-color: var(--klee);
}

@media (max-width: 768px) {
    .button-container {
        flex-direction: row;
        gap: 0.75rem;
    }
    
    .button-container .button-wrapper {
        flex: 1;
    }

    .button-container .form-button,
    .button-container .back-button,
    .button-container .sharepic-file-input-text,
    .button-container .sharepic-unsplash-search-button {
        height: 48px;
    }
}

.advanced-editing-button-container {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.advanced-editing-button {
    width: auto;
    min-width: 250px;
}

