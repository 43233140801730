/* Impressum und Datenschutz spezifische Stile */
.page-container {
    max-width: 800px;
    margin: 70px auto;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
}

.page-container h1, .page-container h2, .page-container h3 {
    color: var(--font-color-h);
}

.page-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.page-container h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
    margin-top: 30px;
}

.page-container h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    margin-top: 20px;
}

.page-container p {
    margin-bottom: 15px;
    line-height: 1.6;
}

.page-container a {
    color: var(--font-color-h);
    text-decoration: none;
    border-bottom: 1px solid var(--font-color-h);
    transition: color 0.3s ease, border-color 0.3s ease;
}

.page-container a:hover {
    color: var(--dunkelgruen);
    border-color: var(--dunkelgruen);
}

.page-container ul {
    padding-left: 20px;
    margin-bottom: 15px;
}

.page-container li {
    margin-bottom: 10px;
}

footer {
    text-align: center;
    padding: 20px;
    background-color: var(--font-color-h);
    color: var(--weiß);
    border-top: 1px solid #ddd;
}

footer a {
    color: var(--weiß);
    text-decoration: underline;
}

footer a:hover {
    color: #ddd;
}
