.backup-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.backup-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.backup-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.backup-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.backup-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .backup-slider {
  background-color: #46962b;
}

input:checked + .backup-slider:before {
  transform: translateX(26px);
}

.backup-label {
  font-size: 0.9rem;
  color: var(--sand);
}

@media screen and (max-width: 768px) {
  .backup-toggle {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .backup-switch {
    width: 50px;
    height: 28px;
  }

  .backup-slider:before {
    height: 20px;
    width: 20px;
  }

  input:checked + .backup-slider:before {
    transform: translateX(22px);
  }

  .backup-label {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .backup-switch {
    width: 40px;
    height: 24px;
  }

  .backup-slider:before {
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
  }

  input:checked + .backup-slider:before {
    transform: translateX(16px);
  }
}