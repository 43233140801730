.nav-menu {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--background-color);
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.nav-menu.open {
  display: flex;
  transform: translateX(0);
}

.nav-menu-item {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1.5rem;
  font-family: 'PT Sans', Arial, sans-serif;
  font-weight: bold;
  color: var(--font-color-h);
  text-decoration: none;
  margin-bottom: 1rem;
}

.nav-menu .nav-dropdown {
  margin-bottom: 1rem;
}

.nav-menu .nav-dropdown > span {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'PT Sans', Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.nav-menu .nav-dropdown > span .nav-icon {
  color: var(--font-color-h);
  margin-left: 10px;
}

.nav-menu .nav-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 0.5rem;
}

.nav-menu .nav-dropdown ul li {
  margin-top: 0.5rem;
}

.nav-menu .nav-dropdown-content {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -1rem;
}

.nav-menu .nav-dropdown-content a {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 1.25rem;
  color: var(--font-color-h);
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.nav-menu .nav-dropdown-content a:hover {
  background-color: var(--background-color-alt);
}

.nav-icon {
  margin-right: 10px;
  font-size: 1.5rem;
  color: var(--font-color-h);
}

.nav-icon-right {
  font-size: 1.5rem;
  color: var(--font-color-h);
  margin-left: 0.5rem;
}

/* Animationen */
.dropdown-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.dropdown-enter-active {
  max-height: 500px;
  opacity: 1;
}

.dropdown-exit {
  max-height: 500px;
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}

.dropdown-exit-active {
  max-height: 0;
  opacity: 0;
}

.disabled-link span {
    color: var(--text-muted);
    cursor: default;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 8px;
}
