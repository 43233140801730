/* Grundlegende Modal-Struktur */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: var(--background-color-alt);
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* Überschriften */
.modal-content h2 {
    color: var(--font-color-h);
    margin-top: 0;
    margin-bottom: 20px;
}

/* Eingabefeld */
.link-name-input {
    font-family: 'PT Sans', Arial, sans-serif;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 0.9em;
    outline: none;
    color: var(--font-color);
    background-color: var(--weiß);
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    margin-bottom: 15px;
}

.link-name-input:focus {
    border: 2px solid var(--grashalm);
    padding: 8px;
}

/* Buttons */
.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.save-button, .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 0.9em;
}

.save-button {
    background-color: var(--klee);
    color: var(--weiß);
}

.save-button:hover {
    background-color: var(--grashalm);
}

.cancel-button {
    background-color: var(--sand);
    color: var(--tanne);
}

.cancel-button:hover {
    background-color: var(--sonne);
}

/* Fehlermeldungen */
.error-message {
    color: var(--sonne);
    font-size: 0.8em;
    margin-top: 4px;
    margin-bottom: 8px;
}

/* Erfolgsmeldungen */
.success-message {
    color: var(--klee);
    font-size: 0.8em;
    margin-top: 4px;
    margin-bottom: 8px;
}

/* Liste der gespeicherten Links */
.saved-links {
    margin-top: 20px;
}

.saved-links h3 {
    color: var(--font-color-h3);
    margin-bottom: 10px;
}

.saved-links ul {
    list-style-type: none;
    padding: 0;
}

.saved-links li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--background-color-sand);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.saved-links button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 0.8em;
}

.saved-links button:first-of-type {
    background-color: var(--himmel);
    color: var(--weiß);
}

.saved-links button:first-of-type:hover {
    background-color: var(--klee);
}

.saved-links button:last-of-type {
    background-color: var(--sonne);
    color: var(--tanne);
}

.saved-links button:last-of-type:hover {
    background-color: var(--grashalm);
    color: var(--weiß);
}

/* Responsive Design */
@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        padding: 15px;
    }

    .save-button, .cancel-button {
        padding: 8px 15px;
        font-size: 0.8em;
    }

    .saved-links li {
        flex-direction: column;
        align-items: flex-start;
    }

    .saved-links button {
        margin-left: 0;
        margin-top: 10px;
    }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--font-color);
}

.close-button:hover {
  color: var(--sonne);
}
