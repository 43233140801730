.welcome-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  backdrop-filter: blur(5px);
  padding: 20px;
  box-sizing: border-box;
}

.welcome-popup {
  background-color: var(--background-color);
  padding: 25px 30px;
  border-radius: 24px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.2);
  max-width: 1000px;
  width: 100%;
  text-align: left;
  border: none;
  animation: popupFadeIn 0.5s ease-out;
}

@keyframes popupFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.welcome-button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 25px;
}

.welcome-popup h1 {
  font-size: 2em;
  margin-bottom: 25px;
  text-align: center;
  color: var(--font-color-h);
  font-weight: 800;
  letter-spacing: -0.02em;
}

.feature-section h2 {
  text-align: center;
  margin-bottom: 25px;
  font-size: 1.4em;
  color: var(--font-color-h);
  font-weight: 700;
  letter-spacing: -0.01em;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.feature-card {
  background: linear-gradient(145deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  padding: 25px;
  border-radius: 18px;
  border: 2px solid var(--tanne);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  cursor: default;
}

.feature-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border-color: var(--dunkelgruen);
}

.feature-emoji {
  font-size: 2.2em;
  display: block;
  margin-bottom: 15px;
}

.feature-card h3 {
  font-size: 1.3em;
  margin-bottom: 12px;
  color: var(--font-color-h);
  font-weight: 700;
  letter-spacing: -0.01em;
}

.feature-card p {
  font-size: 1em;
  line-height: 1.4;
  color: var(--font-color);
  margin: 0;
}

.beta-info {
  text-align: center;
  margin-top: 25px;
  font-size: 0.95em;
  color: var(--font-color);
  font-style: italic;
  opacity: 0.8;
}

.welcome-button {
  padding: 12px 28px;
  border: none;
  border-radius: 30px;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  letter-spacing: -0.01em;
}

.newsletter-button {
  background-color: var(--dunkelgruen);
  color: var(--weiß);
}

.newsletter-button:hover {
  background-color: var(--font-color-h);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.later-button {
  background-color: var(--font-color-h);
  color: var(--background-color);
}

.later-button:hover {
  background-color: var(--dunkelgruen);
  color: var(--weiß);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .welcome-popup {
    padding: 20px;
  }

  .feature-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .welcome-popup h1 {
    font-size: 1.6em;
    margin-bottom: 20px;
  }

  .feature-section h2 {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .feature-card {
    padding: 20px;
  }
  
  .feature-emoji {
    font-size: 1.8em;
    margin-bottom: 12px;
  }
  
  .feature-card h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .feature-card p {
    font-size: 0.95em;
  }

  .welcome-button {
    width: 100%;
    padding: 10px 20px;
    font-size: 0.95em;
  }

  .beta-info {
    font-size: 0.9em;
    margin-top: 20px;
  }
}

.popup-terms {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--background-color); /* Hintergrund ist weiß */
  padding: 10px 20px; /* Padding links und rechts 20px */
  box-shadow: 0 -2px 5px rgba(236, 214, 214, 0.3);
  text-align: left; /* Text linksbündig */
  z-index: 1000;
  display: flex;
  align-items: center;
  box-sizing: border-box; /* Sicherstellen, dass das Padding zur Gesamtbreite gehört */
}

.terms-text {
  flex-grow: 1;
}

.terms-link {
  color: var(--font-color-h);
  text-decoration: underline;
}

.button-terms {
  background-color: var(--font-color-h);
  color: var(--background-color);
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 20px; /* Abstand zwischen Text und Button */
}

.button-terms:hover {
  background-color: var(--dunkelgruen); /* Aktualisiert auf dunkelgrün beim Hover */
  color: var(--weiß); /* Beibehaltung der Schriftfarbe */
}

@media (max-width: 768px) {
  .welcome-popup-overlay {
    display: none;
  }

  .popup-terms {
    flex-direction: column;
    padding: 15px;
    padding-bottom: 25px;
    gap: 10px;
    text-align: center;
  }

  .terms-text {
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .button-terms {
    margin-left: 0;
    padding: 10px 25px;
    font-size: 1em;
    width: auto;
    justify-content: center;
    align-self: center;
  }
}

@media (max-width: 480px) {
  .popup-terms {
    padding: 10px;
  }

  .terms-text {
    font-size: 0.8em;
  }

  .button-terms {
    font-size: 0.9em;
    padding: 8px 20px;
  }
}