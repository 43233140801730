/* Checkbox Styles */
.checkbox-wrapper-28 {
    --size: 15px;
    position: relative;
    display: inline-flex;
    padding: 5px;
}

.checkbox-wrapper-28 .promoted-input-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-wrapper-28 label {
    color: var(--weiß);
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border: 1.5px solid var(--sand);
    border-radius: 8px;
    padding: 8px 12px 8px 35px;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.checkbox-wrapper-28 label:hover {
    background-color: #007a54;
}

.checkbox-wrapper-28 label::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: var(--size);
    height: var(--size);
    border: 1.5px solid var(--weiß);
    border-radius: 3px;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
}

.checkbox-wrapper-28 .checkbox-svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%) scale(0.8);
    width: calc(var(--size) - 4px);
    height: calc(var(--size) - 4px);
    stroke: var(--weiß);
    stroke-width: 3px;
    fill: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.checkbox-wrapper-28 input:checked ~ label .checkbox-svg {
    opacity: 1;
    stroke: var(--tanne);
    transform: translateY(-50%) scale(0.8) rotate(3deg);
}

.platform-checkboxes {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

/* Mobile Anpassungen für Checkboxen */
@media (max-width: 768px) {
    .checkbox-wrapper-28 {
        margin: 0 0 12px 0;
        padding: 0;
    }

    .checkbox-wrapper-28 label {
        padding: 6px 25px 6px 40px;
        font-size: 0.9em;
    }

    .checkbox-wrapper-28 label::before {
        --size: 13px;
        left: 15px;
    }

    .checkbox-wrapper-28 .checkbox-svg {
        left: 17px;
        width: calc(var(--size) - 4px);
        height: calc(var(--size) - 4px);
    }

    .platform-checkboxes {
        gap: 4px;
        flex-direction: column;
        width: 100%;
    }
}
